<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="filter">
        <!-- Name -->
        <!-- <b-col cols="12" md="4" class="mb-2">
          <label>{{ $t("user_id") }}</label>
          <b-form-input
            v-model="filter.user_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col> -->
        <!-- email -->
        <!-- <b-col cols="12" md="4" class="mb-2">
          <label>{{ $t("email") }}</label>
          <b-form-input
            v-model="filter.email"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col> -->
        <!-- username -->
        <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("Username") }}</label>
          <b-form-input
            v-model="filter.username"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>
        <!-- phone -->
        <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("Phone") }}</label>
          <b-form-input
            v-model="filter.phone"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>
        <!-- ref -->
        <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("Ref") }}</label>
          <b-form-input
            v-model="filter.ref"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>
        <!-- code -->
        <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("Code") }}</label>
          <b-form-input
            v-model="filter.code"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("Serial") }}</label>
          <b-form-input
            v-model="filter.serial"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>
        <!-- Type -->
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Card type") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="filter.type"
            :options="cardTypeOptions"
            class="w-100"
            :reduce="(val) => val.value"
          />
        </b-col>
        <!-- Status -->
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Status") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="filter.status"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
          />
        </b-col>
        <!-- action -->
        <b-col cols="12" md="3" class="d-flex align-items-end justify-content-start" style="padding-bottom: 10px">
          <b-button variant="outline-secondary" @click="resetFilter()">
            {{ $t("Reset") }}
          </b-button>
          <b-button variant="primary" class="ml-2" @click="searchFilter()">
            {{ $t("Search") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormInput,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardBody,
    vSelect,
  },
  props: {
    usernameFilter: {
      type: [String, null],
      default: null,
    },
    phoneFilter: {
      type: [String, null],
      default: null,
    },
    refFilter: {
      type: [String, null],
      default: null,
    },
    codeFilter: {
      type: [String, null],
      default: null,
    },
    serialFilter: {
      type: [String, null],
      default: null,
    },
    typeFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },

    cardTypeOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {},
  setup(props, { emit }) {
    const filter = ref({
      username: props.usernameFilter,
      phone: props.phoneFilter,
      ref: props.refFilter,
      code: props.codeFilter,
      type: props.typeFilter,
      serial: props.serialFilter,
      status: props.statusFilter,
    });
    const resetFilter = () => {
      emit("update:usernameFilter", null);
      emit("update:phoneFilter", null);
      emit("update:refFilter", null);
      emit("update:codeFilter", null);
      emit("update:serialFilter", null);
      emit("update:typeFilter", null);
      emit("update:statusFilter", null);
      filter.value = {
        username: null,
        phone: null,
        ref: null,
        code: null,
        serial: null,
        type: null,
        status: null,
      };
    };

    const searchFilter = () => {
      emit("update:usernameFilter", filter.value.username);
      emit("update:phoneFilter", filter.value.phone);
      emit("update:refFilter", filter.value.ref);
      emit("update:codeFilter", filter.value.code);
      emit("update:serialFilter", filter.value.serial);
      emit("update:typeFilter", filter.value.type);
      emit("update:statusFilter", filter.value.status);
    };
    const statusOptions = [
      { label: "waiting", value: "waiting" },
      { label: "processing", value: "processing" },
      { label: "success", value: "success" },
      { label: "card_fail", value: "card_fail" },
      { label: "delete", value: "delete" },
    ];
    return {
      resetFilter,
      searchFilter,
      filter,
      statusOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
